import React from "react";
import { Link } from "react-router-dom";
import { useStateContext } from "../context/StateContext";

import logo from "../images/woolistone-192.png";

import "../styles/header.scss";

const Header = () => {
  const { width, menu, setMenu } = useStateContext();

  return (
    <header className={menu ? "menu open" : "menu"}>
      {width <= 950 ? (
        <>
          <nav>
            <Link to={"/"} onClick={() => setMenu(prev => !menu)}>Home</Link>
            <Link to={"/about"} onClick={() => setMenu(prev => !menu)}>About</Link>
            <Link to={"/contact"} onClick={() => setMenu(prev => !menu)}>Contact</Link>
          </nav>
          <button onClick={() => setMenu(prev => !menu)} className={menu ? "open" : ""}>{menu ? "Close" : "Menu"}</button>
        </>
      ) : (
        <div className="header-banner">
          <nav>
            <Link to={"/"}>Home</Link>
            <Link to={"/about"}>About</Link>
          </nav>
          <Link to={"/"} className="banner-logo">
              <img src={logo} className="logo-header" alt="logo" />
          </Link>
          <nav>
            <Link to={"/contact"}>Contact</Link>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
