import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useStateContext } from "../context/StateContext";

import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Review from "../components/Review";

import { workAbout, workData, workReviews } from "../data/workData";

import "../styles/timeline.scss";
import "../styles/team.scss";


function About() {
    const { width } = useStateContext();
    const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const today = new Date();
    const thisMonth = today.getMonth() + 1;
    const thisYear = today.getFullYear();

    const calcDuration = (job) => {
        const date = job.date.split(" ");
        let year = thisYear - date[1];
        let month = thisMonth - (monthList.indexOf(date[0]) + 1);
        if (month < 0) {
            --year;
            month = 13 + month;
        }
        return `${year} yr ${month} mos`;
    }

    return (
        <Layout>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
            >
                <div className="link-section">
                    <h1 className='pb-20 bold'>About Thomas</h1>
                    <div>
                        {workAbout.map((sentence, i) => {
                            return <p key={i}>{sentence}</p>
                        })}
                    </div>
                    <Link className='btn' to="/contact">Contact me</Link>
                </div>
                <div className="alt-section">
                    <h1 className='pb-20 bold'>Entrepreneur</h1>
                    <div className="alt-section-text">
                        <div>
                            Over the years, I have continuously improved and expanded my skills as a software engineer. As a result of this growth, I have been able to offer web development services to clients.
                        </div>
                        <div>You can find my current live projects on the homepage.</div>
                    </div>
                    <Link className='btn cta-btn' to="/">See Projects</Link>
                </div>
                <section id="cd-timeline" className="cd-container">
                    {workData.map((job, i) => {
                        const duration = calcDuration(job);
                        return (
                            <div className="cd-timeline-block" key={i}>
                                <div className="cd-timeline-img">
                                </div>

                                <div className="cd-timeline-content">
                                    <div className="cd-timeline-content-title">
                                        <img src={job.image} alt="logo" />
                                        <h1>{job.title}</h1>
                                    </div>
                                    <div className="cd-timeline-content-description">
                                        <h2>@ {job.company}</h2>
                                        <p>{job.description}</p>
                                        {width >= 1170 && (
                                            <div className="tools">
                                                {job.tools.map((tool, i) => {
                                                    return <img src={tool} key={i} alt="tool" />
                                                })}
                                            </div>
                                        )}
                                    </div>
                                    <span className="cd-date">
                                        {/* <div>{job.date}</div> */}
                                        {width >= 1170 && (
                                            <div>{job.duration === "current" ? duration : job.duration}</div>
                                        )}

                                        {width <= 1170 && (
                                            <div className="cd-timeline-content-bottom">
                                                <div>{job.duration === "current" ? duration : job.duration}</div>
                                                <div className="tools">
                                                    {job.tools.map((tool, i) => {
                                                        return <img src={tool} key={i} alt="tool" />
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </span>
                                    <Link to={job.link} className='cta-btn' target="_blank" rel="noopener noreferrer">See client's site</Link>
                                </div>
                            </div>
                        );
                    })}
                </section>
                <section className="job-reviews">
                    <h1>Some Words from Colleagues</h1>
                    {workReviews.map((review, i) => {
                        return <Review
                            key={i}
                            name={review.name}
                            job={review.job}
                            review={review.quote}
                            description={review.context}
                            solo={true}
                        />
                    })}
                </section>
                <Footer />
            </motion.div>
        </Layout>
    );
}

export default About;