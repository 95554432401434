import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <div className="final-section">
            <h1>Simple websites exercising high design and precision</h1>
            <Link className='btn cta-btn' to="/contact">Contact Us</Link>
            <div className="footer-foot">
                <Link to="/terms-of-service">Terms of Service</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
        </div>
    );
  };
  
  export default Footer;