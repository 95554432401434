import React from 'react';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
// import { useStateContext } from "../context/StateContext";

import Layout from "../components/Layout";
import Footer from "../components/Footer";

import emma from "../images/websites/emma_both.png";
import olivia from "../images/websites/olivia_both.png";
import present from "../images/websites/present_both.png";
import maths from "../images/websites/maths_both.png";

// import olivia from "../images/websites/present/present-pc.png";
// import present from "../images/websites/present/present-mobile.png";

import "../styles/home.scss";
import SmartImage from '../components/SmartImage';

function Home() {
    // const { width } = useStateContext();

    const websites = [
        ["emma", emma, "https://emmalahaye.art/"],
        ["olivia", olivia, "https://olivialahaye.com/"],
        ["present", present, "https://presentplanning.com/"],
        ["maths", maths, "https://maths-tutor-ai.com/"],
    ];

    return (
        <Layout>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
            >
                <div className='home'>
                    <div className='home-title'>
                        <h1>Intelligent Design</h1>
                        <h2 className='under-heading'>
                            <div>Where functionality meets simplicity for a pleasant and to the point experience</div>
                        </h2>
                        <div className="home-buttons">
                            <Link className='btn' to={"/contact"}>Contact</Link>
                        </div>
                    </div>
                    <div className="websites">
                        {websites.map((website, i) => {
                            return <SmartImage src={website[1]} link={website[2]} styling="website" alt={website[0]} number={i} key={i} />
                        })}
                    </div>
                    <div className='home-about'>
                        <h2>
                            <div>
                                Our website expression is founded on ideas of simplicity and redefining the norms of user experience.
                                They exercises a visual language to expand and focus on what it is to be a website that cements the
                                house’s position as a minimalist in the world of website design. Don't be silly, slide in our emails
                                and lets get the party started.
                            </div>
                        </h2>
                        <div className="home-buttons">
                            <Link className='btn' to={"/about"}>About</Link>
                        </div>
                    </div>
                </div>
                <Footer />
            </motion.div>
        </Layout>
    );
}

export default Home;

// https://hackernoon.com/21-best-developer-portfolio-examples-p61j31wi
// https://www.ourwbn.com/
// https://www.maisonmargiela.com/en-gb/