import tiktok from "../images/work/TikTok.png";
import boons from "../images/work/boons.jfif";
import welleasy from "../images/work/welleasy.png";
import flick from "../images/work/flick.png";
import bluefuse from "../images/work/bluefuse.png";
import bath from "../images/work/bath.jpg";
import landmark from "../images/work/landmark.png";

// import firebase from "../images/tools/firebase.png";
import angular from "../images/tools/angular.png";
import flutter from "../images/tools/flutter.png";
import fs from "../images/tools/fs.png";
import ad from "../images/tools/ad.png";
import laravel from "../images/tools/laravel.png";
import msqls from "../images/tools/msqls.png";
import react from "../images/tools/react.png";
import shopify from "../images/tools/shopify.png";
import brain from "../images/tools/brain.png";
import feishu from "../images/tools/feishu.webp";
import wordpress from "../images/tools/wordpress.png";

// ----------------- Thomas ------------------

const thomasAbout = [
  "Welcome to my website. Here you can get a quick overview of what I've done in the last few years.",
  "I am a web developer, technical specialist and sneaker reseller. While focusing on my career and climbing the ladder, I also found time to work on side ventures.",
  "Feel free to reach out with any questions."
];

const thomasData = [
  {
    title: "Product & Project Specialist and Subject Matter Expert",
    company: "TikTok",
    image: tiktok,
    date: "March 2023",
    duration: "current",
    description:
      "Shifting my focus to new projects and improving the current products and creating a new all-in-one resource hub for Technical Project Specialists.",
    tools: [ad, feishu, tiktok],
    link: "https://business.tiktok.com/",
  },
  {
    title: "Performance Technical Project Specialist",
    company: "TikTok",
    image: tiktok,
    date: "Nov 2021",
    duration: "2 yr",
    description:
      "I quickly became the Performance Pillar's Subject Matter Expert and thus, the person providing technical and analytical support to my teams as well as innovating tools and procedures alongside.",
    tools: [ad, feishu, tiktok],
    link: "https://business.tiktok.com/",
  },
  {
    title: "Frontend Software Developer",
    company: "Boons Media",
    image: boons,
    date: "May 2021",
    duration: "1 yr",
    description:
      "Working with an Ad Tech company to deliver multiple websites to generate cash flow for predominantly blog companies. Working predominantly with WordPress but eventually moved all the sites to React.",
    tools: [ad, react, wordpress],
    link: "https://www.boonsmedia.com",
  },
  {
    title: "Lead Frontend Software Developer",
    company: "Well Easy [Contract]",
    image: welleasy,
    date: "Feb 2021",
    duration: "6 mos",
    description:
      "As the sole developer, I worked on creating the best UX and UI for this new start up. Leading the team with my technical proficiency",
    tools: [shopify],
    link: "https://welleasy.co.uk/",
  },
  {
    title: "Freelance UI/UX Analyst",
    company: "Flick Tech [Contract]",
    image: flick,
    date: "Nov 2020",
    duration: "1 yr",
    description:
      "Created an indepth analysis report aiming to increase Flick's customer retention focusing on their user interface and experience.",
    tools: [fs],
    link: "https://www.flick.social/",
  },
  {
    title: "Full Stack Software Developer",
    company: "Bluefuse Systems",
    image: bluefuse,
    date: "Jun 2018",
    duration: "2 yr",
    description:
      "My first job as a Software Developer, introduced new features, refactored parts and maintained the internal website. I learnt a lot from the fantastic Senior Developer.",
    tools: [angular, laravel, msqls],
    link: "https://bluefusesystems.com/",
  },
  {
    title: "Computer Science",
    company: "Univesity of Bath",
    image: bath,
    date: "Sep 2016",
    duration: "4 yr",
    description:
      "I studied Computer Science at the University of Bath with an industrial placement. My personal favorite modules were the HCI modules and advanged algorithms and data structures.",
    tools: [flutter],
    link: "https://www.bath.ac.uk/",
  },
  {
    title: "Advanced Leadership Course",
    company: "Landmark forum",
    image: landmark,
    date: "Sep 2014",
    duration: "2 yr",
    description:
      "Landmark is a company providing courses aiming to bring a profound impact in personal and professional aspects of someone's life.",
    tools: [brain],
    link: "https://www.landmarkworldwide.com/",
  },
];

const thomasReviews = [
  {name: "Alexis", job: "Technical Product Specialist @ TikTok", context: "A colleague referencing my project building educational resources", quote: "I would like to be included in more of Thomas' projects as he is forward-thinking and open to fresh ideas on optimising processes."},
  {name: "Shaked", job: "Team & Pillar Lead, Monetization Product Operations @ TikTok", context: "From my Line Manager as I transition to a new position within TikTok", quote: "A true pleasure seeing your growth and improvement here! Keep on the hard work, your impact is seen and it leads to changes! You are a role model for the rest and I think that u will do great in your current role. Good luck!!!"},
  {name: "Arvind", job: "Lead - Product Management and Operations - Tools @ TikTok", context: "From a XFN colleague having supported feature releases for our tools and platforms", quote: "As always great pleasure working with you, looking forward to many more successful launches and interesting projects together with you."},
  {name: "Jane", job: "Training Project Manager @ TikTok", context: "From a XFN colleague on creating and delivering education resources", quote: "Since you have been helping me create ByteLearning channel as well as provide useful commdocs to your fellow colleagues, this has played a significant part in ensuring new joiners' success in their roles. However, it would be great if we can get you involved in more projects (or tasks) that you have not done before/haven't had a chance to do before. For example, it would be great if you could conduct a product training session or even a refreshment session for STPS, or GTPS, or vendors so that they can learn from your skills, knowledge/expertise, and experience. Please don't be shy to reach out to me or your lead and I can surely help you organize the training sessions."},
  {name: "Kieran", job: "Co-founder @ Well Easy", context: "Working with a Shopify start-up", quote: "Thomas was an excellent addition to the WellEasy team, he successfully introduced new features to welleasy.co.uk and improved user experience significantly. He is a fast-learner and took initiative and worked on Shopify Liquid even though having never worked on a Shopify store before."},
]

export const workAbout = thomasAbout;
export const workData = thomasData
export const workReviews = thomasReviews
