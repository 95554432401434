import { Link } from "react-router-dom";
import { useStateContext } from "../context/StateContext";

import "../styles/review.scss";

const Review = ({ name, job, review, description, link = null, solo = false }) => {
    const { width } = useStateContext();

  return (
    <>
        <blockquote className="review">
            {link === null ? "" : (
              <>
                {width <= 950 && (
                    <Link to={link} className="btn" target="_blank" rel="noopener noreferrer"> See the website </Link>
                )}
              </>
            )}
            
            {solo || width >= 950 ? (
              <h2 className="review-title">{description}</h2>
            ) : ""}
            <div className="quote">&#8220;</div>
            <p>{review}</p>
            <cite>
                <div className="cite-name">{name}</div>
                <div>{job}</div>
            </cite>
        </blockquote>
    </>
  )
}

export default Review