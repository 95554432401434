import { useState } from "react";
import { motion } from "framer-motion";

import Layout from "../components/Layout";
import Donut from 'react-spinning-donut';


import "../styles/contact.scss";



function Contact() {
    const [clicked, setClicked] = useState(false);
    const email = "woolistont@gmail.com"

    const handleClick = () => {
        if (!clicked) navigator.clipboard.writeText(email);
        setClicked(prev => !clicked);
    }

    return (
        <Layout>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
            >
                <div className="contact link-section">
                    <div className="text-center">No ugly contact form, phew! Just my email, much better.</div>
                    <div className="email">{email}</div>
                    <button
                        className={clicked ? "email-copy" : ""}
                        onClick={handleClick}
                    >
                        {clicked ? "Copied!" : "Copy Email"}
                    </button>
                    <div className="text-center">Enjoy this donut while you're here</div>
                    <Donut />
                </div>
            </motion.div>
        </Layout>
    );
}

export default Contact;