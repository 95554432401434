import { useState } from "react";
import { Link } from "react-router-dom";

const SmartImage = ({ src, link = null, styling = "", alt = null, number }) => {
    const [imageLoading, setImageLoading] = useState(true);
    console.log(src, link, styling, alt);

    return (
        <>
            {link === null ?
                <div className={imageLoading ? `s${number} ${styling} smart-image wait-image` : `${styling} smart-image`}>
                    <img
                        src={src}
                        alt={alt === null ? "image" : alt}
                        onLoad={() => setImageLoading(false)}
                    // loading="lazy"
                    />
                    <div class="placeholder">
                        <div class="animated-background"></div>
                    </div>
                </div>
                :
                <Link to={link} className={imageLoading ? `s${number} ${styling} smart-image wait-image` : `${styling} smart-image`} rel="noopener noreferrer" target="_blank">
                    <img
                        src={src}
                        alt={alt === null ? "image" : alt}
                        onLoad={() => setImageLoading(false)}
                    // loading="lazy"
                    />
                    <div class="placeholder">
                        <div class="animated-background"></div>
                    </div>
                </Link>
            }
        </>
    );
};

export default SmartImage;
