import Header from "./Header";


const Layout = ({ children }) => {

  return (
    <main className="layout">
        <Header />
        <div className="header-buffer">
          {children}
        </div>
    </main>
  )
}

export default Layout